import React from "react"
import PropTypes from 'prop-types';
import { withStyles} from "@material-ui/core";
import Layout from '../../components/layout';
import Helmet from 'react-helmet';

const styles = theme => ({
  image: {
    float: "right",
  },
  [theme.breakpoints.down("xs")]: {
    image: {
      display: "none",
    },
  },
  modules : {
    fontSize: "80%"
  },
  list : {
    '& li' : {
      marginTop: "5px"
    }
  }
});

function Content(props) {
 const {classes} = props;
  return (
    <Layout>
      <Helmet>
        <title>Quentin Howe - QHFS Limited</title>
      </Helmet>
      <img src="/images/person.jpg" alt="Quentin Howe" className={classes.image}/>
      <h2>Quentin Howe</h2>

      <p>Quentin has over twenty years of financial experience specialising in investments, mortgages and financial
        planning. He is committed to keeping up to date with the ever changing regulatory requirements and is currently
        a member of the Chartered Insurance Institute (CII), the Institute of Financial Services (IFS), the
        Chartered Institute for Securities and Investment (CISI) and the Personal Finance Society (PFS).</p>

      <p>From March 2016 his professional qualifications include:</p>
      <ul className={classes.list}>
        <li>Chartered Financial Planner (CII)</li>
        <li>Certified Financial Planner CM Chartered MCSI (CISI)</li>
      </ul>

      <p>In order to obtain the above he has completed the following financial examinations with the above institutions:</p>

      <ul className={classes.list}>
        <li>CII Level 4 Award in Pensions Update</li>
        <li>RO8 Pensions Update</li>
        <li>RO7 Advanced Mortgage Advice</li>
        <li>Advanced Diploma in Financial Planning (CII) including:
          <ul className={classes.modules}>
            <li>Financial Planning Process (AF5 paper)</li>
            <li>Taxation and Trusts (G10 paper)</li>
            <li>Personal Investment Planning (G20 paper)</li>
            <li>Business Financial Planning (G30 paper)</li>
            <li>Long Term Care, Life and Health Protection (G80 paper)</li>
          </ul>
        </li>
        <li>Diploma in Financial Planning (CII) including:
          <ul className={classes.modules}>
            <li>Saving and Investment (SV1 paper)</li>
            <li>Pension Income Options (J05 paper)</li>
            <li>Mortgage Advice (CF6 paper)</li>
            <li>Lifetime Mortgage Activities (CF7 paper)</li>
          </ul>
        </li>
        <li>Certificate in Financial Planning (CII) including:
          <ul className={classes.modules}>
            <li>UK Financial Service Regulation and Ethics (CF1 paper)</li>
            <li>Investment and Risk (CF2 paper)</li>
            <li>Financial Protection (CF3 paper)</li>
            <li>Retirement Planning (CF4 paper)</li>
            <li>Integrated Financial Planning (CF5 paper)</li>
            <li>Long Term Care Insurance (CF8 paper)</li>
            <li>Pensions Simplification (CF9 paper)</li>
            <li>Life Office Administration (FA1 paper)</li>
            <li>Financial Services and their Regulation (FP1 paper)</li>
            <li>Protection, Savings and Investments (FP2 paper)</li>
            <li>Identifying and Satisfying Client Needs (FP3 paper)</li>
            <li>Home Reversion Plans (HR1)</li>
          </ul>
        </li>
        <li>Certificate in Mortgage Advice and Practice (IFS)</li>
        <li>Certificate of Regulated General Insurance (IFS)</li>
        <li>Certificate in Equity Release (CII)</li>
        <li>Certificate in Mortgage Advice (CII)</li>
        <li>Certificate in Financial Planning & Lifetime Mortgage Activities (CII)</li>
        <li>Certificate in Financial Planning & Long Term Care Insurance (CII)</li>
        <li>Certificate in Financial Administration (Life) (CII)</li>
        <li>Certificate in Life and Pensions (CII)</li>
      </ul>

      <p>As a result of these qualifications Quentin carries the designatory letters '<b>FPFS</b>' (Advanced Diploma, Personal Finance Society) and '<b>CFPCM Chartered MCSI</b>' (Certified Financial Planner, Institute of Financial Planning).</p>

      <p><a href="/about">About QHFS</a></p>
    </Layout>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);